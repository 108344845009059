declare global {
  let fbq: (
    action: 'track',
    event: string,
    data: Record<string, unknown>,
    config?: Record<string, unknown>,
  ) => void | undefined;
  interface Window {
    dataLayer: Record<string, unknown>[];
    VWO?: {
      event: (event: string, params?: Record<string, string | number | undefined>) => void;
    };
  }
}

export enum Gender {
  FEMALE = 'female',
  MALE = 'male',
  OTHER = 'other',
}

export const AppRoute = {
  LANDING: '/',
  QUIZ: '/quiz',
  SUMMARY: '/summary',
  EMAIL: '/email',
  PLANS: '/plans',
  CHECKOUT: '/checkout',
  SUCCESS: '/success',
  ERROR: '/error',
  ACCESS: '/access',
  LOGIN: '/login',
  SHIPPING: '/shipping',
  UPSELL: (name?: string) => `/upsell${name ? `/${name}` : ''}`,
};

export type QuestionOption = {
  label: string;
  key: string;
  nextQuestion?: string;
  img_url?: string;
  deselectAll?: boolean;
  score?: number;
  icon?: string;
  [key: string]: string | number | null | undefined | boolean;
};

export type Question = {
  type: string;
  label: string;
  relationshipLabel?: string;
  description?: string;
  icon?: string;
  image?: string;
  key: string;
  options?: QuestionOption[];
  theme?: string;
  custom?: Record<string, unknown>;
};

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  CAD = 'CAD',
  NZD = 'NZD',
  GBP = 'GBP',
  AUD = 'AUD',
}

export enum Locale {
  EN = 'en',
  EN_US = 'en-US',
  EN_CA = 'en-CA',
  EN_NZ = 'en-NZ',
  EN_GB = 'en-GB',
  EN_AU = 'en-AU',
  ES = 'es',
  EU = 'eu',
}

export type PriceBase = {
  finalPrice: number;
  price: number;
  discount?: number;
  currency: Currency;
};

export interface ProductBase {
  id: string;
  name: string;
  title: string;
  key: string;
  type: 'oneTime' | 'upsell';
  isSubscription: boolean;
  isDownsell?: boolean;
  prices?: PriceBase[];
  plan?: {
    trail?: number;
    period?: number;
  };
  autorefund?: boolean;
  tags?: Array<string>;
}

export enum FirebaseFunc {
  CREATE_LEAD = 'createLead',
  UPDATE_LEAD = 'updateLead',
  CREATE_ORDER = 'createPaymentSecretsForOrder',
  STRIPE_SAVE_ORDER = 'stripeSaveOrder',
  BUY_UPSELL = 'buyUpsell',
  UPDATE_CLIENT_PAYMENT_METHOD = 'updateClientPaymentMethod',
  UPDATE_LEAD_CUSTOM_FIELDS = 'updateLeadCustomFields',
  FETCH_LEAD = 'fetchLead',
}
