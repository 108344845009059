const breakpoints = {
  base: '0rem',
  md: '48rem', // 768px
  lg: '70rem', // 1 120px
  xl: '80rem', // 1 280px
};

export const baseTheme = {
  breakpoints,
  styles: {
    global: {
      '*': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
      },
    },
  },
};
