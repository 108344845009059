export {
  Gender,
  type Question,
  type QuestionOption,
  AppRoute,
  Currency,
  Locale,
  type ProductBase,
  FirebaseFunc,
  type PriceBase,
} from './types/general';

export { TermsAndPrivacyCopy } from './components/TermsAndPrivacyCopy';
export { Button } from './components/Button';

export * from './hooks/useStorage';
export * from './hooks/useGTMEvents';
export * from './hooks/useCountDown';
export * from './hooks/useFirebase';
export * from './hooks/useTracking';
export { useDate } from './hooks/useDate';
export { useHydration } from './hooks/useHydration';
export * from './hooks';

export { useQuestionnaire } from './hooks/useQuestionnaire';
export { constants } from './constants';

export { generalUtils } from './utils/general';
export { formulas } from './utils/formulas';

/** providers */
export { UserContext, UserContextProvider } from './providers/UserProvider';

export { baseTheme } from './theme/baseTheme';
