// eslint-disable-next-line @typescript-eslint/no-var-requires
const path = require('path');

const isDev = process.env.NODE_ENV === 'development';

const SUPPORTED_LANGS = ['en', 'es', 'de', 'fr', 'it', 'pt'];

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  debug: false,
  reloadOnPrerender: isDev,
  localePath: (locale, ns) => {
    const lang = locale.match(/[a-z]{2}/)[0];
    const langPath = SUPPORTED_LANGS.includes(lang) ? lang : 'en';

    return `${path.resolve('./public/locales')}/${langPath}/${ns}.json`;
  },
  ns: [
    'checkout',
    'common',
    'createAccount',
    'email',
    'landing',
    'loader',
    'plans',
    'quiz',
    'summary',
    'upsells',
    'upsellMeditation',
    'upsellBundle',
    'success',
    'articles',
  ],
  serializeConfig: false,
  i18n: {
    defaultLocale: 'en-US',
    locales: [
      'en-US',
      'en-GB',
      'en-AU',
      'en-NZ',
      'en-CA',
      'de-DE',
      'en-IE',
      'fr-FR',
      'it-IT',
      'nl-NL',
      'af-ZA',
      'es',
      'de',
      'fr',
      'it',
      'pt',
      'es-MX',
      'es-ES',
      'es-AR',
      'es-CO',
      'es-VE',
      'es-PE',
      'es-CL',
      'rest-ww',
    ],
  },
};
