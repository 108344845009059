import { monthsArray, weekDaysArray } from '../constants/dates';

/**
 *
 * @param Date
 */
export const useDate = (d?: Date) => {
  const date = d || new Date();

  return {
    year: date.getFullYear(),
    month: monthsArray[date.getMonth()],
    weekDay: weekDaysArray[date.getDay()],
    getFormatedDate: (dateToFormat: Date) => {
      return `${dateToFormat.getUTCDate()} ${monthsArray[dateToFormat.getMonth()]} ${dateToFormat.getFullYear()}`;
    },

    addDays: (days: number) => {
      const newDate = new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000);

      return {
        newDate,
        date: newDate.getDate(),
        weekDay: weekDaysArray[newDate.getDay()],
        month: monthsArray[newDate.getMonth()],
      };
    },
  };
};
