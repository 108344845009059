import { httpsCallable, Functions } from 'firebase/functions';
import { useCallback } from 'react';

import { FirebaseFunc } from '../types/general';

interface LeadResponse {
  id: string;
}

export const useFirebase = (firebaseFunctions: Functions) => {
  const updateLead = useCallback(
    async <UserRequest extends { id: string }>(data: UserRequest) => {
      const response = await (
        await httpsCallable<UserRequest, LeadResponse>(firebaseFunctions, FirebaseFunc.UPDATE_LEAD)
      )(data);

      return response.data;
    },
    [firebaseFunctions],
  );

  const createLead = useCallback(
    async <UserRequest extends { id?: string }>(data: UserRequest) => {
      if (data.id) {
        return await updateLead({ id: data.id, ...data });
      } else {
        delete data.id;

        const response = await (
          await httpsCallable<UserRequest, LeadResponse>(firebaseFunctions, FirebaseFunc.CREATE_LEAD)
        )(data);

        return response.data;
      }
    },
    [firebaseFunctions, updateLead],
  );

  const updateClientPaymentMethod = useCallback(
    async (leadId: string, paymentMethodId: string) => {
      const response = await (
        await httpsCallable(firebaseFunctions, FirebaseFunc.UPDATE_CLIENT_PAYMENT_METHOD)
      )({ leadId, paymentMethodId });

      return response.data;
    },
    [firebaseFunctions],
  );

  const updateLeadCustomFields = useCallback(
    async (leadId: string, customFields: Record<string, unknown>) => {
      const response = await (
        await httpsCallable(firebaseFunctions, FirebaseFunc.UPDATE_LEAD_CUSTOM_FIELDS)
      )({ leadId, customFields });

      return response.data;
    },
    [firebaseFunctions],
  );

  const fetchLead = useCallback(
    async (leadId?: string, email?: string) => {
      const response = await (
        await httpsCallable<unknown, { error?: string; id: string; email: string; orders: Array<{ planId: string }> }>(
          firebaseFunctions,
          FirebaseFunc.FETCH_LEAD,
        )
      )({ leadId, email });

      return response.data;
    },
    [firebaseFunctions],
  );

  return {
    createLead,
    updateLead,
    updateClientPaymentMethod,
    updateLeadCustomFields,
    fetchLead,
  };
};
