export const generateHash = async (message: string) => {
  // Convert the message to an ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(message);

  // Use SubtleCrypto.digest() to generate a hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // Convert the ArrayBuffer to a hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
};
