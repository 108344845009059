import { useCallback, useContext } from 'react';

import { UserContext } from '../providers/UserProvider';

interface DefaultLead {
  id?: string;
  quizAnswers?: Record<string, unknown>;
  email?: string;
}

export const useLead = <TLead extends DefaultLead>() => {
  const { user, setUser } = useContext(UserContext);
  const lead = user as TLead;

  const updateUser = useCallback(
    (newUser: Partial<TLead>) => {
      setUser((state) => ({ ...state, ...newUser }));
    },
    [setUser],
  );

  const updateQuizAnswers = (quizAnswers: TLead['quizAnswers']) => {
    setUser((state: TLead) => ({ ...state, quizAnswers: { ...state.quizAnswers, ...quizAnswers } }));
  };

  return {
    id: lead.id,
    lead,
    quizAnswers: lead.quizAnswers as TLead['quizAnswers'],
    updateUser,
    updateQuizAnswers,
  };
};
