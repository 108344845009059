import { PriceBase, ProductBase, useLead } from '@shared/common';
import { PaymentProvider } from '@shared/payments';
import { useEffect, useState } from 'react';

import { QuizAnswers } from '../types';

export type Product = Omit<ProductBase, 'plan'> & {
  plan: { interval: number };
  isDownsell: boolean;
  isTrail?: boolean;
  isUpsell?: boolean;
  trial?: {
    prices?: PriceBase[];
    period_days?: number;
  };
  tags?: ['ww', 'oneTime'];
};

interface Lead {
  id?: string;
  selectedPlan?: Product;
  upsellSubscriptionId?: string;
  initialOrderId?: string;
  email?: string;
  hasDownsell?: boolean;
  quizAnswers?: QuizAnswers;
  paymentProvider: PaymentProvider;
  cartItems?: Product[];
}

export const useAppLead = () => {
  const leadData = useLead<Lead>();
  const [selectedPlan, setSelectedPlan] = useState<Product | undefined>(undefined);

  const updateCartItems = (product?: Product) => {
    const currentData = leadData.lead.cartItems || [];

    if (product && !currentData?.find((prod) => prod.id === product.id)) {
      currentData?.push(product);
    }

    leadData.updateUser({ cartItems: currentData || [] });
  };

  useEffect(() => {
    setSelectedPlan(leadData.lead.selectedPlan);
  }, [leadData]);

  return { ...leadData, selectedPlan, updateCartItems, gender: leadData?.quizAnswers?.gender || 'female' };
};
