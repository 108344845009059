import { Text } from '@chakra-ui/react';
import Link from 'next/link';
import { FC } from 'react';

export const TermsAndPrivacyCopy: FC<{ projectName: string; color?: string }> = ({
  projectName,
  color = '#535353',
}) => (
  <Text textStyle='small' color={color} textAlign='center' fontWeight={500}>
    Copyright © 2023 {projectName}. All rights reserved. By continuing you agree to our{' '}
    <Link href='/terms-of-services'>Terms & Conditions</Link> and <Link href='/privacy-policy'>Privacy Policy</Link>.
  </Text>
);
