import { useEffect, useState } from 'react';

export const useCountDown = (
  initialTime: number,
  options?: { disableReset?: boolean; isPaused?: boolean; onFinish?: () => void },
) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!options?.isPaused) {
        setTime((prevState: number) => {
          if (prevState >= 0) {
            const value = prevState - 1;

            if (value > 0) {
              return value;
            } else {
              if (options?.onFinish) {
                options.onFinish();
              }

              return options?.disableReset ? 0 : initialTime;
            }
          }

          clearInterval(interval);

          return prevState;
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [initialTime, setTime, options]);

  useEffect(() => {
    setTime(initialTime);
  }, [initialTime]);

  return time;
};
