import cookie from 'js-cookie';

export const useCookies = () => {
  const params = cookie.get('x-params');
  const utm = cookie.get('x-utm');

  const parseJson = (json: string) => {
    try {
      return JSON.parse(json);
    } catch (_err) {
      return {};
    }
  };

  return {
    fbclid: cookie.get('_fbc') || cookie.get('x-fbclid') || null,
    fbpixel: cookie.get('_fbp') || null,
    ip: cookie.get('x-ip') || null,
    city: cookie.get('x-city') || null,
    countryCode: cookie.get('x-country-code') || null,
    country: cookie.get('x-country') || null,
    region: cookie.get('x-region') || null,
    params: params ? decodeURIComponent(params) : null,
    longitude: Number(cookie.get('x-longitude')) || null,
    latitude: Number(cookie.get('x-latitude')) || null,
    zip: cookie.get('x-postal') || null,
    utm: utm ? parseJson(utm) : {},
  };
};
