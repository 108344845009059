import { useAppRouter } from './useAppRouter';

export enum Coupon {
  happy10 = 'happy10',
  happy20 = 'happy20',
}

export const useAppParams = () => {
  const { query } = useAppRouter();
  const coupon = query.coupon && (query.coupon as string) in Coupon ? (query.coupon as string) : undefined;

  return {
    coupon,
  };
};
