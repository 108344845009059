import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useAppRouter = (nextRoute?: string) => {
  const router = useRouter();

  const getDefaultQueryParams = () => {
    const { query } = router;
    const params: Record<string, string> = {};

    if (query.flowName) {
      params.flowName = query.flowName as string;
    }

    if (query.locale) {
      params.locale = query.locale as string;
    }

    if (query.variant) {
      params.variant = query.variant as string;
    }

    if (query.coupon) {
      params.coupon = query.coupon as string;
    }

    return params;
  };

  useEffect(() => {
    if (nextRoute && typeof nextRoute === 'string') {
      router.prefetch(nextRoute);
    }
  }, [router, nextRoute]);

  return {
    ...router,
    pushNextRoute: (queryParams?: Record<'flowName', string>) => {
      const defaultQueryParams = getDefaultQueryParams();

      if (nextRoute) {
        router.push(
          queryParams || defaultQueryParams
            ? { pathname: nextRoute, query: { ...defaultQueryParams, ...queryParams } }
            : nextRoute,
        );
      }
    },
    flowName: router.query.flowName as undefined | 'cortisol' | 'cortisolMethod',
    variant: router.query.variant as undefined | 'periods',
    isSouthAfrica: (router.query.locale as string) === 'af-ZA',
    isLocaleDefault: router.locale === 'en-US',
    isCheckoutPage: /\/checkout/.test(router.asPath),
    isPlansOrCommitPage: /\/(commit|plans)/.test(router.asPath),
    isPlansPage: /\/cortisol\/plans(\?.*)?$/.test(router.asPath),
  };
};
