import { useCallback } from 'react';

import { generateHash } from '~/utils';

import { useCookies } from './useCookies';

declare global {
  let gtag:
    | ((
        action: 'event' | 'set' | 'js' | 'config',
        event: string | Date,
        params?: Record<string, unknown> | string,
      ) => void)
    | undefined;
}

interface UserProperties {
  id?: string;
  uuid?: string;
  flow_name?: string;
}

type Event =
  | 'purchase'
  | 'quiz_start'
  | 'quiz_finish'
  | 'email_left'
  | 'checkout_visit'
  | 'quiz_question_answer'
  | 'plans_visit'
  | 'plans_select'
  | 'checkout_submit'
  | 'conversion'
  | 'session_init';

export const useAnalytics = () => {
  const { utm, ip } = useCookies();
  const isEnabled = typeof gtag !== 'undefined';

  const getCommonParams = useCallback(
    (): Record<string, unknown> => ({
      ...utm,
    }),
    [utm],
  );

  const log = useCallback(
    (eventName: Event, eventParams?: Record<string, unknown>) => {
      const params = { ...getCommonParams(), ...eventParams };

      if (isEnabled) {
        gtag?.('event', eventName, params);
      }
    },
    [isEnabled, getCommonParams],
  );

  const setUserProperties = useCallback(
    (properties: UserProperties) => {
      const { id, ...restProperties } = properties;

      if (isEnabled) {
        if (id) {
          gtag?.('set', 'user_id', id);
        }

        if (Object.values(restProperties).some(Boolean)) {
          const filteredProperties: Record<string, string> = {};

          for (const key in restProperties) {
            if (properties[key as keyof UserProperties]) {
              filteredProperties[key] = properties[key as keyof UserProperties] as string;
            }
          }

          gtag?.('set', 'user_properties', filteredProperties);
        }
      }
    },
    [isEnabled],
  );

  const setUserData = useCallback(
    (data?: { email?: string }) => {
      if (isEnabled) {
        gtag?.('set', 'user_data', data);
      }
    },
    [isEnabled],
  );

  const sessionInit = async () => {
    const fingerprints = [
      navigator.userAgent,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      navigator.languages.join(','),
      [screen.width, screen.height].join('x'),
    ];

    if (ip) {
      fingerprints.push(ip);
    }

    setUserProperties({
      uuid: await generateHash(fingerprints.join(':')),
    });

    log('session_init');
  };

  return { log, setUserProperties, setUserData, sessionInit };
};
