import { Currency } from '../types/general';

import { useGTMEvents } from './useGTMEvents';

interface Order {
  type?: 'initial' | 'upsell';
  id: string;
  finalPrice: number;
  finalPriceUSD?: number;
  currency: Currency;
  leadId: string;
  items: { key: string; name: string; finalPrice: number };
}

export const useTracking = () => {
  const { trackSuccessInitialOrder } = useGTMEvents();

  const trackPurchase = (order: Order, user?: Record<string, string | undefined>) => {
    const key = order.items.key;
    trackSuccessInitialOrder({
      type: order.type || 'initial',
      transactionId: order.id,
      transactionTotal: order.finalPrice,
      transactionTotalUsd: order.finalPriceUSD || order.finalPrice,
      currencyCode: order.currency,
      transactionProducts: [
        {
          sku: order.items.key,
          name: order.items.name,
          price: order.items.finalPrice,
          quantity: 1,
        },
      ],
      user,
    });

    if (order.type === 'upsell' && typeof window.VWO !== 'undefined') {
      const event = /bundle/.test(key)
        ? 'bundle'
        : /meditation/.test(key)
          ? 'meditation'
          : /stress/.test(key)
            ? 'stress'
            : 'mobility';
      window.VWO.event(`purchase_upsell_${event}`, {
        value: order.finalPriceUSD,
      });
    }
  };

  return {
    trackPurchase,
  };
};
