import { Currency } from '@shared/common';

import { useAppRouter } from './useAppRouter';

const LOCALE_CURRENCY_MAP: Record<string, Currency> = {
  'en-US': Currency.USD,
  'en-GB': Currency.GBP,
  'en-AU': Currency.AUD,
  'en-NZ': Currency.NZD,
  'en-CA': Currency.CAD,
  'es-ES': Currency.EUR,
  'de-DE': Currency.EUR,
  'en-IE': Currency.EUR,
  'fr-FR': Currency.EUR,
  'it-IT': Currency.EUR,
  'nl-NL': Currency.EUR,
  'af-ZA': Currency.USD,
};

const LANGUAGES = ['en', 'es', 'de', 'fr', 'it', 'pt'];

const DEFAULT_LANG = 'en';

export const useLocale = () => {
  const { locale } = useAppRouter();
  const currency = locale && LOCALE_CURRENCY_MAP[locale] ? LOCALE_CURRENCY_MAP[locale] : Currency.USD;
  const isImperial = locale ? ['en-US', 'es'].includes(locale) : false;

  const isRestWW = Boolean(
    ['rest-ww', 'af-ZA', 'es-MX', 'es-AR', 'es-CO', 'es-VE', 'es-PE', 'es-CL', 'de', 'fr', 'it', 'pt', 'es'].find(
      (loc) => loc === locale,
    ),
  );

  const localeLang = locale ? locale.match('^[a-z]{2}(-|$)')?.[0]?.replace('-', '') || DEFAULT_LANG : DEFAULT_LANG;
  const lang = LANGUAGES.includes(localeLang) ? localeLang : DEFAULT_LANG;

  return {
    locale,
    currency,
    isImperial,
    isRestWW,
    lang,
  };
};
